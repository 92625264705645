<template>
  <div class="container-fluid m-0 p-0">
    <div
      class="row m-0 p-0 justify-content-center align-items-center"
      style="height: 80vh"
    >
      <div
        class="col-10 col-xl-4 p-5 shadow bg-white rounded position-relative"
      >
        <p class="text-danger">
          <span class="font-800 opp">Oops! </span>
          <span class="page font-600">Page not found</span>
        </p>

        <button type="button" class="btn bt-main w-100" @click="gotohome()">
          Go To Home Page
        </button>
        <!-- <img src="../assets/sorry.png" alt="" class="img-r img-fluid" /> -->
        <Notfrond class="img-r img-fluid color-team" />
      </div>
    </div>
  </div>
</template>

<script>
import Notfrond from "../components/svg/Notfrond.vue";
export default {
  name: "NotFound",
  components: { Notfrond },
  methods: {
    gotohome() {
      this.$router.push({ path: "/" });
    },
  },
};
</script>

<style scoped>
.opp {
  font-size: 3.5em;
}
.page {
  font-size: 1.2em;
}
.img-r {
  position: absolute;
  top: 55%;
  left: 110%;
  width: 50%;
  transform: translate(-50%, -50%);
}
</style>
